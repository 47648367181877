import React, { useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import GameHome from '../Game/GameHome'  // Ensure this path is correct

const StartGamePage: React.FC = () => {
  const [gameStarted, setGameStarted] = useState(false)

  const startGame = () => {
    setGameStarted(true)
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 500, mx: 'auto', textAlign: 'center', mt: 5 }}>
      {gameStarted ? (
        <GameHome />
      ) : (
        <>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Welcome to PromptGuessr!
          </Typography>
          <Typography variant="body1" sx={{ mb: 5 }}>
            Test your skills and see if you can guess the prompt. Click the button below to start the game!
          </Typography>
          <Button variant="contained" color="primary" onClick={startGame}>
            Start Game
          </Button>
        </>
      )}
    </Box>
  )
}

export default StartGamePage
