import axios from 'axios' 

export const guessPrompt = async (id: string, guess: string) => {
  try {
    const url = 'https://promptguessr-pb625wheoa-uc.a.run.app/api/guess/guess-prompt'
    // const url = 'http://localhost:3001/api/guess/guess-prompt'
    const response = await axios.post(url, {
      id,
      guess,
    }) 
    return response.data 
  } catch (error) {
    console.error('Error guessing prompt:', error) 
    throw error 
  }
} 
