import React from 'react'
import './App.css'
import GameHome from './views/Game'
import StartGamePage from './views/welcome/StartGamePage'
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <StartGamePage />
      </header>
    </div>
  )
}

export default App
