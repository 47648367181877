import React, { useState } from 'react' 
import { Box, TextField, Button, Typography } from '@mui/material' 
import { guessLetter } from '../../../../core/guessing/guessLetter' 
import { guessPrompt } from '../../../../core/guessing/guessPrompt' 

interface HangmanTextProps {
  structure: string 
  id: string 
  onGameWon: () => void 
  onGameLost: () => void 
}

const HangmanText: React.FC<HangmanTextProps> = ({ structure, id, onGameWon, onGameLost }) => {
  const [guess, setGuess] = useState('') 
  const [message, setMessage] = useState('') 
  const [displayText, setDisplayText] = useState(structure.replace(/1/g, '_').replace(/0/g, ' ')) 
  const [isGameWon, setIsGameWon] = useState(false) 
  const [remainingGuesses, setRemainingGuesses] = useState(10) 
  const [guessedLetters, setGuessedLetters] = useState<string[]>([]) 
  const [guessedWords, setGuessedWords] = useState<string[]>([]) 

  const handleGuessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGuess(event.target.value) 
  } 

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      checkGuess() 
    }
  } 

  const checkGuess = async () => {
    if (remainingGuesses <= 0) return 

    if (guess.length === 1) {
      if (guessedLetters.includes(guess)) {
        setMessage('You already guessed that letter!') 
        setGuess('') 
        return 
      }

      try {
        const response = await guessLetter(id, guess) 
        if (response.error) {
          setMessage('Error processing your guess.') 
        } else if (response.found) {
          let updatedDisplay = displayText
            .split('')
            .map((char, index) => (structure[index] === '1' && response.positions?.includes(index) ? guess : char))
            .join('') 

          setDisplayText(updatedDisplay) 
          setMessage('Nice guess!') 
          setGuessedLetters((prev) => [...prev, guess]) 

          if (!updatedDisplay.includes('_')) {
            setIsGameWon(true) 
            onGameWon() 
          }
        } else {
          setGuessedLetters((prev) => [...prev, guess]) 
          setRemainingGuesses((prev) => prev - 1) 
          if (remainingGuesses - 1 <= 0) {
            setMessage('You have lost! No guesses left.') 
            onGameLost() 
          } else {
            setMessage('Incorrect guess, try again!') 
          }
        }
      } catch (error) {
        console.error('Error checking guess:', error) 
        setMessage('Error processing your guess.') 
      }
    } else if (guess.length > 1) {
      if (guessedWords.includes(guess)) {
        setMessage('You already guessed that word!') 
        setGuess('') 
        return 
      }
      
      await checkSingleWordOrPrompt(guess) 
    }

    setGuess('') 
  } 

  const checkSingleWordOrPrompt = async (promptGuess: string) => {
    try {
      const response = await guessPrompt(id, promptGuess) 
      if (response.correct) {
        if (promptGuess.split(' ').length === structure.split('0').length) {
          setDisplayText(promptGuess) 
          setMessage('Congratulations! You guessed the entire prompt correctly!') 
          setIsGameWon(true) 
          onGameWon() 
        } else {
          let updatedDisplay = displayText.split('') 
          response.positions.forEach((pos: number) => {
            const wordIndex = pos - response.positions[0] 
            if (wordIndex >= 0 && wordIndex < promptGuess.length) {
              updatedDisplay[pos] = promptGuess[wordIndex] 
            }
          }) 
          setDisplayText(updatedDisplay.join('')) 
          setMessage(`You guessed the word "${promptGuess}" correctly!`) 
          setGuessedWords((prev) => [...prev, promptGuess]) 

          if (!updatedDisplay.includes('_')) {
            setIsGameWon(true) 
            onGameWon() 
          }
        }
      } else {
        setGuessedWords((prev) => [...prev, promptGuess]) 
        setRemainingGuesses((prev) => prev - 1) 
        if (remainingGuesses - 1 <= 0) {
          setMessage('You have lost! No guesses left.') 
          onGameLost() 
        } else {
          setMessage('Incorrect guess. Try again!') 
        }
      }
    } catch (error) {
      console.error('Error guessing the prompt:', error) 
      setMessage('Error processing your guess.') 
    }
  } 

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ fontFamily: 'Monospace', letterSpacing: '2px', whiteSpace: 'pre-wrap' }}>
        {displayText.split('').join('')}
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 1 }}>
        Remaining Guesses: {remainingGuesses}
      </Typography>
      <TextField
        label="Enter a letter or guess the whole thing"
        variant="outlined"
        value={guess}
        onChange={handleGuessChange}
        onKeyPress={handleKeyPress}
        sx={{
          mt: 2,
          mb: 2,
          width: '100%',
          input: { color: 'white', backgroundColor: '#333', borderColor: 'white' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: 'white' },
            '&:hover fieldset': { borderColor: 'yellow' },
            '&.Mui-focused fieldset': { borderColor: 'green' },
          },
        }}
        InputLabelProps={{
          style: { color: '#aaa' },
        }}
        inputProps={{ maxLength: 50 }}
        disabled={isGameWon || remainingGuesses <= 0}
      />
      {!isGameWon && remainingGuesses > 0 && (
        <Button variant="contained" onClick={checkGuess} disabled={!guess.trim().length} sx={{ mt: 1 }}>
          Guess
        </Button>
      )}
      <Typography variant="subtitle1" sx={{ mt: 2, color: 'secondary' }}>
        {message}
      </Typography>
      {remainingGuesses <= 0 && !isGameWon && (
        <Typography variant="subtitle1" sx={{ mt: 2, color: 'red' }}>
          You have lost! No guesses left.
        </Typography>
      )}
    </Box>
  ) 
} 

export default HangmanText 
