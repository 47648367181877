import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography, Button, CircularProgress } from '@mui/material'
import { generateImage } from '../../../core/generation/generateImage'
import HangmanText from './HangmanText'
import Confetti from 'react-confetti'
import { useWindowSize } from '@react-hook/window-size'

const GameHome: React.FC = () => {
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [imageId, setImageId] = useState<string | null>(null)
  const [structure, setStructure] = useState<string | null>(null)
  const [isGameWon, setIsGameWon] = useState<boolean>(false)
  const [isGameLost, setIsGameLost] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const hasFetchedImage = useRef(false)
  const [width, height] = useWindowSize()

  useEffect(() => {
    if (!hasFetchedImage.current) {
      fetchNewImage()
      hasFetchedImage.current = true
    }
  }, [])

  const fetchNewImage = async () => {
    setIsLoading(true)
    try {
      const data = await generateImage()
      setImageId(data.id)
      setImageUrl(data.imageUrl)
      setStructure(data.structure)
      setIsGameWon(false)
      setIsLoading(false)
    } catch (error) {
      console.error('Failed to fetch image:', error)
      setIsLoading(false)
    }
  }

  const handleGameWon = () => {
    setIsGameWon(true)
  }
  const handleGameLost = () => {
    setIsGameLost(true)
  }

  const handleNextImage = () => {
    fetchNewImage()
  }

  if (isLoading) {
    return (
      <Box sx={{ width: '100%', maxWidth: 500, mx: 'auto', textAlign: 'center', mt: 5 }}>
        <CircularProgress />
        <Typography>Generating an image.. This might take a minute</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 500, mx: 'auto', textAlign: 'center' }}>
      {isGameWon && <Confetti width={width} height={height} />}
      <img src={imageUrl!} alt="Generated artwork to guess" style={{ maxWidth: '100%', height: 'auto', marginBottom: '16px' }} />
      {structure && (
        <HangmanText key={imageId} structure={structure} id={imageId!} onGameWon={handleGameWon} onGameLost={handleGameLost}/>
      )}
      {(isGameWon || isGameLost) && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextImage}
          sx={{ mt: 3 }}
        >
          Next Image
        </Button>
      )}
    </Box>
  )
}

export default GameHome
