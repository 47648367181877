import axios from 'axios' 

export const generateImage = async () => {
    try {
        const url = 'https://promptguessr-pb625wheoa-uc.a.run.app/api/generations/generate'
        // const url = 'http://localhost:3001/api/generations/generate'
        const response = await axios.get(url) 

        if (!response.data || typeof response.data !== 'object' || !response.data.id || !response.data.imageUrl) {
            console.error('Invalid response from server:', response) 
            throw new Error('Invalid response from server: Missing id or imageUrl') 
        }

        return response.data
    } catch (error) {
        console.error('Error fetching image:', error) 
        throw error
    }
} 
