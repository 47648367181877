import axios from 'axios' 

interface GuessLetterResponse {
  found: boolean 
  positions?: number[] 
  error?: string 
}

export const guessLetter = async (id: string, letter: string): Promise<GuessLetterResponse> => {
  try {
    // const url = 'http://localhost:3001/api/guess/guesses'
    const url = 'https://promptguessr-pb625wheoa-uc.a.run.app/api/guess/guesses'
    const response = await axios.post(url, { id, letter }) 

    if (response.data && typeof response.data.found === 'boolean') {
      return response.data
    } else {
      console.error('Unexpected response format:', response.data) 
      throw new Error('Unexpected response format') 
    }
  } catch (error) {
    console.error('Error making guess:', error) 
    return { found: false, error: 'Error making guess' } 
  }
} 
